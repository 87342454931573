import axios from 'axios';
import ApiService from '../API';
import { TokenService, UserService } from '../Storage';

const AuthURL = process.env.REACT_APP_AUTH_BASE_URL;

const AuthService = {
  async login(email, password) {
    const emailBase64 = window.btoa(email);
    const passwordBase64 = window.btoa(password);

    try {
      const { data: data_1 } = await axios
        .post(
          `${AuthURL}/v1/login`,
          {
            grant_type: 'client_credentials',
            profile: process.env.REACT_APP_AUTH_PROFILE
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Basic ${`${emailBase64}:${passwordBase64}`}`,
            },
          });
      TokenService.saveToken(data_1.access_token);
      TokenService.saveRefreshToken(data_1.refresh_token);
      UserService.saveUserId(data_1.sub)
      ApiService.setHeader();
    } catch (error) {
      throw error;
    }
  },

  async logout() {
    try {
      return axios.delete(`${AuthURL}/v1/revoke`, {
        data: { refresh_token: TokenService.getRefreshToken() },
      });
    } catch (error) {
      return error
    }
  },

  async redefinePassword(oldPassword, newPassword) {
    try {
      return axios.put(
        `${AuthURL}/v1/redefine-password`, {
        'old_password': oldPassword,
        'new_password': newPassword
      },
        {
          headers: {
            Authorization: `Bearer ${TokenService.getToken()}`
          }
        }
      )
    } catch (error) {
      throw error
    }
  },

  async sendEmail(email) {
    try {
      return axios.post(
        `${AuthURL}/v1/redefine-password`, {
        "username": email,
        "frontend": process.env.REACT_APP_LOCATION,
        "location": "/newPassword",
        "profile": 'pdv'
      }
      )
    } catch (error) {
      throw error
    }
  },

  async recoveryPassword(password, token) {
    try {
      return axios.patch(
        `${AuthURL}/v1/redefine-password`, {
        new_password: password,
        token
      }
      )
    } catch (error) {
      throw error
    }
  },

  async newAcessToken() {
    const refreshToken = TokenService.getRefreshToken();
    try {
      return axios.patch(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/renew`, {
          refresh_token: refreshToken,
        })
    } catch (error) {
      return error
    }
  },
};

export default AuthService;