import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress, withStyles } from '@material-ui/core';
import styles from './styles';

function Loading(props) {
  const { classes, open } = props;

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress style={{backgroundColor: "#50007C", color: 'white'}} />
    </Backdrop>
  );
}
Loading.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles, { withTheme: true })(Loading);
