import React, { createRef } from 'react';
import { IconButton, withStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import styles from './styles';

function Snackbar(props) {
  const { classes, children } = props;
  const notistackRef = createRef();

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      action={(key) => (
        <IconButton onClick={onClickDismiss(key)}>
          <CloseIcon className={classes.closeActionButton} />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
}

Snackbar.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.object,
};
export default withStyles(styles, { withTheme: true })(Snackbar);
