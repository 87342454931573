import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  '@global': {
    /**
     * Desative o contorno do foco, que é o padrão em alguns navegadores como
     * chrome ao focar elementos
     */
    '*:focus': {
      outline: 0,
    },
    '.color-white': {
      color: theme.palette.common.white,
    },
    '.color-black': {
      color: theme.palette.common.black,
    },
    '.content': {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(4),
      display: 'flex',
      flexGrow: 1,
      overflow: 'auto',
    },
    '.lg-mg-top': {
      marginTop: `${theme.spacing(18)}px !important`,
      [theme.breakpoints.down('md')]: {
        marginTop: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: `${theme.spacing(14)}px !important`,
      },
    },
    '.no-decoration': {
      textDecoration: 'none !important',
    },
    '.p-4': {
      padding: `${theme.spacing(4)}px !important`,
    },
  },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
