import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const LoadingContext = createContext();
export const LoadingProvider = (props) => {
  const { children } = props;
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleBackdropOpen = () => {
    setOpenBackdrop(true);
  };

  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };

  return (
    <LoadingContext.Provider
      value={{ openBackdrop, handleBackdropOpen, handleBackdropClose }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

LoadingProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default () => useContext(LoadingContext);
