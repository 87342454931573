import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import ApiService from '../../services/API';
import { TokenService, UserService } from '../../services/Storage';
import AuthService from '../../services/Session';

export const SessionContext = createContext();

const userDefault = {
  identificationName: '',
  tradeName: '',
  responsibleName: '',
  email: '',
};

export const SessionProvider = (props) => {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(userDefault);
  const [loading, setLoading] = useState(true);

  function getUserAuthenticated() {
    const user = UserService.getUser();
    setUserAuthenticated(user);
  }

  function getUserInfo() {
    ApiService.get('/me').then(({ data }) => {
      console.log(data)
      const userData = {
        identificationName: data.data.responsible_name,
        tradeName: data.data.owner.trade_name,
        responsibleName: data.data.owner.responsible_name,
        email: data.data.owner.email,
        ownerId: data.data.owner.id,
      };

      setUserAuthenticated(userData);
      UserService.saveUser(userData);

      return data;
    });
  }

  async function signIn(email, password) {
    const response = await AuthService.login(email, password);
    getUserInfo();
    setIsAuthenticated(true);
    return response;
  }

  function signOut() {
    setUserAuthenticated(userDefault);
    AuthService.logout().then(()=>{
      setIsAuthenticated(false);
      TokenService.removeToken();
      UserService.removeUser();
      UserService.removeUserId();
      ApiService.removeHeader();
    }).catch(()=>{
      setIsAuthenticated(false);
      TokenService.removeToken();
      UserService.removeUser();
      UserService.removeUserId();
      ApiService.removeHeader();
    })
  }

  useEffect(() => {
    try {
      const token = TokenService.getRefreshToken();
      if (token) {
        const { exp } = jwtDecode(token);
        if (exp > new Date().getTime() / 1000) {
          ApiService.setHeader();
          getUserAuthenticated();
          setIsAuthenticated(true);
        }
      }
    } catch (error) {
      
    }
    setLoading(false);
  }, []);

  return (
    <SessionContext.Provider
      value={{ loading, isAuthenticated, userAuthenticated, signIn, signOut }}
    >
      {children}
    </SessionContext.Provider>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default () => useContext(SessionContext);