export default class ConnectionError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message =
      message ||
      'Problemas na comunicação com o servidor. Contate o suporte para mais informações.';
    this.errorCode = errorCode || 503;
  }
}
