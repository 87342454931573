import React from 'react'

import {
  Avatar,
  styled,
  Tooltip,
  withStyles
} from '@material-ui/core'

import PropsType from 'prop-types'
import styles from './styles'

import {
  WhatsApp as WhatsAppIcon,
} from '@material-ui/icons'
import { tooltipClasses } from '@mui/material'

function WhatsAppSuport(props) {
  const { classes } = props

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#4fec67',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#4fec67',
      color: theme.palette.common.black,
      fontSize: '15px'
    },
  }));

  return (
    <a
      href="https://wa.me/message/OPZKIOX6COBLA1"
      target="_blank">
      <BootstrapTooltip
        title="Suporte WhatsApp"
        placement='left'>
        <button
          className={classes.container}>
          <Avatar
            className={classes.whatsAppIcon}
            sizes="medium">
            <WhatsAppIcon className={classes.icon}/>
          </Avatar>
        </button>
      </BootstrapTooltip>
    </a>
  )
}

WhatsAppSuport.propTypes = {
  classes: PropsType.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(WhatsAppSuport)