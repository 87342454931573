export default (theme) => ({
  container: {
    border: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    width: '70px',
    height: '70px',
    borderRadius: '35px',
    cursor: 'pointer',
    '& :hover > svg':{
      fontSize: '55px',
    },
    '-webkit-animation': '$shadow-pulse 1.5s infinite',
    transform: 'scale(1)',
    animation: '$shadow-pulse 1.5s infinite ease-out',
  },
  whatsAppIcon: {
    width: '70px',
    height: '70px',
    borderRadius: '35px',
    backgroundColor: '#4fec67',
  },
  icon: {
    fontSize: '45px', 
    transition: '0.1s all ease-in '
  },
  '@keyframes shadow-pulse': {
    '0%': {
      boxShadow: '0 0 0 0 rgba(79, 236, 103, 0.2)',
      transform: 'scale(0.95)'
    },
    '70%': {
      boxShadow: '0 0 0 5px rgba(79, 236, 103, 0.7)',
      transform: 'scale(1)'
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(79, 236, 103, 0.2)',
    },
  }
})

