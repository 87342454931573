export default class ConflictError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message =
      message ||
      'Conflito de uma ou mais informações enviadas para o servidor. Contate o suporte para mais informações.';
    this.errorCode = errorCode || 409;
  }
}
